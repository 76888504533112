import React from "react"
import ContentSection from "../lib/ContentSection"
import { graphql, useStaticQuery } from "gatsby"
import { tokens } from "@atrilflamenco/react-components"
import { micromark } from "micromark"

export default function Products() {
  const { notion } = useStaticQuery(query)
  const data = {
    id: notion.title,
    title: notion.properties.title.value,
    body: notion.properties.body.value,
  }

  return (
    <ContentSection
      title={data?.title}
      text={micromark(data?.body)}
      button="Regístrate"
      buttonPath="http://atril.clicksound.io:3000/signup"
      bgColor={tokens.colors.gray50}
    />
  )
}

export const query = graphql`
  query {
    notion(title: { eq: "call-to-action" }) {
      title
      properties {
        title {
          value
        }
        body {
          value
        }
      }
    }
  }
`
