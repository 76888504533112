import React from "react"

export default function HeaderSection({ title, subtitle, text }) {
  return (
    <div className="max-w-screen-xl mx-auto">
      {/* <h2 className="text-xl font-extrabold mb-3 text-gray-900">{title}</h2> */}
      <h3 className="text-3xl font-bold mb-3 leading-tight text-gray-700">
        {subtitle}
      </h3>
      {text && (
        <p
          className="mb-8 leading-relaxed text-gray-800 strong:text-primary-500 space-y-3"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </div>
  )
}
