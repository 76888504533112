import React, { useEffect, useState } from "react"
import { Container } from "../components/lib/Container"
import CallToAction from "./../components/sections/CallToActionSection"
import iconSet from "./../styles/iconmoon.json"
import IcomoonReact from "icomoon-react"
import HeaderSection from "./../components/lib/HeaderSection"
import { motion } from "framer-motion"
import { Button } from "@atrilflamenco/react-components"
import Footer from "../components/lib/Footer"

const faq = [
  {
    title: "Suscripción y condiciones de uso",
    content:
      "La suscripción te da derecho a acceder de forma ilimitada durante el período contratado a todos los contenidos y funcionalidades que ofrece Atril Flamenco, incluido tu espacio personal, donde quedarán recogidos tu historial de lectura así como los resultados de tu interacción con los contenidos (guardados, etiquetas, notas, valoraciones...). Este espacio no será visible a otros usuarios. Atril Flamenco se reserva el derecho a cancelarla suscripción, devolviendo el importe no disfrutado, en el caso de un uso indebido o fraudulento de la aplicación y sus contenidos.",
  },
  {
    title: "Cancelación y cambios en la suscripción",
    content:
      "Puedes cancelar tu suscripción en cualquier momento enviando un correo a la dirección suscriptores@atrilflamenco.com, indicando tu nombre, apellidosy DNI; a efectos de mejorar nuestro servicio, añade por favor también el motivo de la cancelación. La cuota en curso que hayas abonado no será devuelta, pero garantizamos que no se te cargará ninguna otra cuota. Para volverte a suscribir y acceder de nuevo a tu espacio personal podrás hacerlosiguiendo el procedimiento normal de suscripción (desde esta misma página), sin necesidad de enviar ningún correo.",
  },
  {
    title: "Métodos de pago",
    content:
      "Te ofrecemos dos métodos de pago: con tarjeta de crédito o débito a través de nuestra plataforma de pago online o mediante transferencia bancaria a la cuenta que se indica durante el proceso de suscripción.",
  },
  {
    title: "Problemas de acceso",
    content:
      "Si una vez suscrito o durante el periodo de prueba detectas problemas a la hora de acceder a los contenidos o las funcionalidades, dispones de un espacio de Ayuda en el escritorio a través del cual puedes notificarnos las incidencias, que miraremos de resolver en el menor tiempo posible. Asimismo, con este mismo fin ponemos a tu disposición la dirección de correo incidencias@atrilflamenco.com. ",
  },
  {
    title: "Destino de tu suscripción",
    content:
      "El importe de la suscripción se destina íntegramente al mantenimiento de la web y la incorporación de mejoras técnicas. La suscripción constituye un acto de compromiso del usuario hacia la labor de divulgación e investigación llevada a cabo por los autores de Atril Flamenco.",
  },
  {
    title: "Derecho de cita",
    content:
      "Los fragmentos de texto y los ejemplos de audio o video ajenos a los autores de los libros y artículos incluidos en Atril flamenco se incorporan al amparo del derecho de cita, previsto y regulado en el apartado primero del artículo 32 de la vigente Ley de Propiedad Intelectual española, que contempla las mismas excepciones para textos y para documentos sonoros o audiovisuales. Con el fin de ajustarnos a los requerimientos legales, indicamos en todas las citas la fuente original utilizada y el nombre completo del autor de la obra. Asimismo, los ejemplos sonoros o audiovisuales nunca corresponden a la obra original íntegra, sino a un fragmento que se introduce como parte intrínseca y necesaria del análisis musical. En este sentido, la elección de los fragmentos ha seguido siempre criterios educativos y científicos, en cuanto que permiten explicar y demostrar fehacientemente teorías, conceptos o características musicales del flamenco.",
  },
]

const razones = [
  {
    icon: "laptop",
    content:
      "Acceso ilimitado e integrado a todos los contenidos, cuando y donde quieras.",
  },
  {
    icon: "binoculars",
    content:
      "Encuentra al instante tus temas de interés a través del buscador dinámico.",
  },
  {
    icon: "pushpin",
    content:
      "Fórmate fácilmente en tus temas favoritos a través de los planes de lectura.",
  },
  {
    icon: "library",
    content:
      "Disfruta de forma interactiva de contenidos rigurosos y bien documentados.",
  },
  {
    icon: "stack",
    content: "Impulsa tus trabajos de investigación sobre el flamenco.",
  },
  {
    icon: "power-cord",
    content: "Encuentra ideas y argumentos para tus creaciones flamencas.",
  },
  {
    icon: "headphones",
    content:
      "Accede a ejemplos de audio y video sin interrumpir la lectura ni salir de la aplicación.",
  },
  {
    icon: "star-full",
    content:
      "Destaca y etiqueta el texto según tus preferencias y temas de interés.",
  },
  {
    icon: "pencil2",
    content: "Crea tus propias notas sobre el contenido que estés consultando.",
  },
  {
    icon: "mug",
    content:
      "Plantéanos tus dudas o haznos observaciones: te ayudaremos y nos ayudarás.",
  },
  {
    icon: "users",
    content:
      "Suscribirse es la mejor garantía de aprovechar los contenidos y las herramientas.",
  },
  {
    icon: "heart",
    content:
      "Tu suscripción nos ayudará a seguir investigando y a ofrecerte contenidos nuevos.",
  },
]

async function fetchSubscriptions() {
  const response = await fetch(`https://api.atrilflamenco.com:5000/getSubCats`)
  const data = await response.json()
  return data
}

export default function Suscripcion() {
  const [active, setActive] = useState(null)
  const [subscriptions, setSubscriptions] = useState(null)

  useEffect(() => {
    fetchSubscriptions().then(setSubscriptions)
  }, [])
  console.log(subscriptions)
  return (
    <>
      <Container>
        <HeaderSection
          title="Suscripción"
          subtitle="Acceso online en cualquier parte del mundo y a cualquier hora"
          text="El coste de la suscripción en cualquiera de sus modalidades es comparable al coste que supondría adquirir anualmente un libro impreso. Te ofrecemos acceso online, en cualquier parte del mundo y a cualquier hora, no sólo a varios libros y artículos, sino a una forma nueva y distinta de estudiar del flamenco, con la posibilidadde comunicarte con el autor."
        />
        <div className="max-w-screen-xl mx-auto">
          <div className={`grid gap-6 mb-10 grid-cols-1 md:grid-cols-3`}>
            {subscriptions?.map(subscription => {
              var price = (parseInt(subscription.price) / 100)
                .toFixed(2)
                .replaceAll(",", ":")
                .replaceAll(".", ",")
                .replaceAll(":", ".")

              return subscription.slug !== "mensual_personalizada" ? (
                <div className="p-5 flex flex-col justify-between items-center text-center rounded space-y-5 border">
                  <div className="space-y-3">
                    <h3 className="font-semibold uppercase">
                      {subscription.title}
                    </h3>
                    <div
                      className="text-sm"
                      dangerouslySetInnerHTML={{ __html: subscription.summary }}
                    />
                  </div>
                  <p className="text-sm text-gray-500 py-3">
                    {subscription.monthsDuration
                      ? subscription.monthsDuration > 1
                        ? subscription.monthsDuration + " meses"
                        : subscription.monthsDuration + " mes"
                      : null}
                    {subscription.monthsDuration > 0 &&
                    subscription.daysDuration > 0
                      ? " y "
                      : null}
                    {subscription.daysDuration
                      ? subscription.daysDuration > 1
                        ? subscription.daysDuration + " días a"
                        : subscription.daysDuration + " día a"
                      : null}
                    {subscription.monthsDuration > 0 ||
                    subscription.daysDuration > 0
                      ? " a "
                      : null}
                    <h3 className="text-fire-500 text-xl font-bold">
                      {price}€{" "}
                    </h3>
                  </p>
                  <Button href="http://atril.clicksound.io:3000/cuenta/suscripciones">
                    Acceder a la suscripción
                  </Button>
                </div>
              ) : null
            })}

            {subscriptions?.map(subscription => {
              var price = (parseInt(subscription.price) / 100)
                .toFixed(2)
                .replaceAll(",", ":")
                .replaceAll(".", ",")
                .replaceAll(":", ".")

              return subscription.slug === "mensual_personalizada" ? (
                <div className="p-5 flex flex-col justify-between items-center text-center rounded space-y-5 border">
                  <div className="space-y-3">
                    <h3 className="font-semibold uppercase">
                      {subscription.title}
                    </h3>
                    <div
                      className="text-sm"
                      dangerouslySetInnerHTML={{ __html: subscription.summary }}
                    />
                  </div>
                  <p className="text-sm text-gray-500 py-3">
                    <h3 className="text-fire-500 text-xl font-bold">
                      {price}€ / mes{" "}
                    </h3>
                  </p>
                  <Button href="http://atril.clicksound.io:3000/cuenta/suscripciones">
                    Acceder a la suscripción
                  </Button>
                </div>
              ) : null
            })}
          </div>
          <h3 className="text-3xl font-bold mb-8 leading-tight text-gray-800">
            ¿Por qué suscribirse?
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 mb-10">
            {razones.map(razon => (
              <div className="flex flex-col items-center space-y-5 p-5 text-center border rounded-xl">
                <IcomoonReact
                  iconSet={iconSet}
                  color="#444"
                  size={32}
                  icon={razon.icon}
                />
                <p className="">{razon.content}</p>
              </div>
            ))}
          </div>

          <h3 className="text-xl font-bold mb-5 leading-tight text-gray-800">
            Preguntas frecuentes
          </h3>
          <div className="mb-8">
            {faq.map((q, i) => (
              <div className="border-b">
                <button
                  onClick={() => setActive(active !== i ? i : null)}
                  className="flex justify-between items-center font-semibold py-3 w-full text-left focus:outline-none"
                >
                  <span>
                    {i + 1}. {q.title}
                  </span>
                  <motion.span
                    animate={{ rotate: active !== i ? 0 : 540 }}
                    className="mx-4 rounded-full p-1 hover:bg-gray-100"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={18}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </motion.span>
                </button>
                <motion.div
                  animate={{ height: active !== i ? 0 : "auto" }}
                  className="overflow-hidden"
                >
                  <p className="mb-5">{q.content}</p>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </Container>
      <CallToAction />
      <Footer />
    </>
  )
}
