import React from "react"
import { Container } from "./Container"
import { Button } from "@atrilflamenco/react-components"
import { ArrowRight } from "react-feather"

export default function ContentSection({
  children,
  title,
  subtitle,
  text,
  button,
  buttonPath,
  childrenTop,
  align = "center",
  className,
  bgColor,
}) {
  return (
    <Container
      bgColor={bgColor}
      className={`overflow-x-hidden text-${align} ${className}`}
    >
      <div className="max-w-screen-xl mx-auto px-5">
        {childrenTop && children}
        <div className="max-w-3xl mx-auto">
          {subtitle && (
            <h4
              className="mb-4 text-lg font-bold text-primary-500"
              style={{ marginTop: childrenTop ? `2rem` : 0 }}
            >
              {subtitle}
            </h4>
          )}
          {title && <h2 className="text-3xl font-bold mb-5">{title}</h2>}
          {text && (
            <p
              className="leading-normal mx-auto text-xl strong:text-primary-500"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </div>
        {!childrenTop && children && <div className="mt-5">{children}</div>}
        {buttonPath && (
          <Button
            variant="contained"
            icon={<ArrowRight color="currentColor" strokeWidth={2} size={16} />}
            iconPosition="end"
            href={buttonPath}
            className="mt-8"
          >
            {button}
          </Button>
        )}
      </div>
    </Container>
  )
}
